import styled from "styled-components"

export const InfoContentWrapper = styled.div`
  ${({ theme }) =>
    `background: linear-gradient(0deg, ${theme.colors.background1} 0%, rgba(255, 255, 255, 0) 100%);
    padding: 0 1.5rem;
  border-radius: 24px;`};
`

export const BgWrapper = styled.div`
  ${({ theme }) =>
    `background: linear-gradient(0deg, ${theme.colors.background1} 0%, rgba(255, 255, 255, 0) 100%);
    padding: 3rem 1.5rem;
  border-radius: 24px;`};

  h2 {
    margin-bottom: 2.5rem;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    flex-basis: 40%;
    margin-left: 10%;
    margin-bottom: 64px;

  ${({ theme }) => theme.breakpoints.desktop} {
    margin-left: 3%;
    margin-right: 7%;
  }
`
