import React from "react"

import * as Styled from "./styles"

import NoImgInfoBlock from "components/NoImgInfoBlock"
import Section from "components/ui/Section"
import Title from "components/ui/Title"

interface COIInformation {
  title: string
  text: string
}

const COIInformationsData: COIInformation[] = [
  {
    title: "What is COI?",
    text: "A certificate of insurance (COI) stands for a proof-showing document of insurance coverage for a particular entity. It's essential for moving in or moving out from almost any building as it provides all important data and security for the tenant, vendor, management company. Without COI, a moving company might not even be allowed to enter the premises.",
  },
  {
    title: "What is the most important for COI?",
    text: "COI has to include specific facts that a property administrator will want to know. The most important information that a COI should consist of is the apartment's address and a list of terms and conditions of the insurance. It’s proof that the moving team and the specific property are both insured and secured from any risk that might appear during the process.",
  },
  {
    title: "How do I get it done simply?",
    text: "Whichever role you play in the process, if all this seems complicated and you feel like you need help managing the COI process, get it done with Movezee. Our solution will provide you with a fully automated, standardized, and simplified process where all data and everyone involved are gathered in one place. Everything for your convenience.",
  },
]

const COISection: React.FC = () => {
  const InfoBlocks = COIInformationsData.map((info, index) => (
    <NoImgInfoBlock
      key={index}
      title={info.title}
      text={info.text}
      subtitle={{ lighter: false }}
    />
  ))

  return (
    <Section>
      <Styled.TitleWrapper>
        <Title>{"Certificate of Insurance"}</Title>
      </Styled.TitleWrapper>
      {InfoBlocks}
    </Section>
  )
}

export default COISection
