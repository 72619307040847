import styled from "styled-components"

export const TitleWrapper = styled.div`
  padding: 4rem 3rem 0;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    margin-left: 10%;

  ${({ theme }) => theme.breakpoints.desktop} {
    margin-left: 3%;
  }
`
