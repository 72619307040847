import * as React from "react"

import Layout from "components/Layout"
import Seo from "components/SEO"

import COISection from "components/COISection"

const CoiPage = () => (
  <Layout>
    <Seo title="COI" />
    <COISection />
  </Layout>
)

export default CoiPage
