import React from "react"

import SubTitle from "components/ui/SubTitle"

import * as Styled from "./styles"

import TextContent from "components/ui/TextContent"

interface Props {
  title: string | React.ReactFragment
  text: string
  subtitle: {
    lighter?: boolean
  }
}

const NoImgInfoBlock: React.FC<Props> = ({ subtitle, title, text }) => (
  <Styled.FlexWrapper>
    <Styled.BgWrapper>
      <Styled.InfoContentWrapper>
        <SubTitle lighter={subtitle.lighter}>{title}</SubTitle>
        <TextContent>{text}</TextContent>
      </Styled.InfoContentWrapper>
    </Styled.BgWrapper>
  </Styled.FlexWrapper>
)

export default NoImgInfoBlock
